import { Injectable } from '@angular/core';
import { Toast } from '@capacitor/toast';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor() { }

  public async showDIalog(msg : string){
    console.log(msg)
    const toast = await Toast.show({
      text: msg,
      duration :  'long',
    })
}
}
