import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminAuthGuardService } from './auth/adminActivateGuard.service';
import { DeactivateAuthGuardService } from './auth/deactivateGuard.service';
import { AuthGuardService } from './auth/guard.service';
import { ContactComponent } from './contact/contact.component';
import { ExternalViewComponent } from './external-view/external-view.component';
import { LoginComponent } from './login/login.component';
import { NewPasswordComponent } from './new-password/new-password.component';

const routes: Routes = [
  
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [DeactivateAuthGuardService] 
  },

  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path : 'external/:id',
    component : ExternalViewComponent
  },
  {
    path : 'external',
    component : LoginComponent
  },
  {
    path: 'new-password',
    component: NewPasswordComponent
  },
  {
    path: 'orders',
    loadChildren: () => import('./logged/logged.module').then( m => m.LoggedPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminModule),
    canActivate: [AdminAuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
