import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map , take} from 'rxjs/operators';

@Injectable()
export class AdminAuthGuardService implements CanActivate {

  canNavigate: boolean;

  constructor(private authService: AuthService, 
            ) {

              }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {


    return this.authService.isAustenitUser.pipe(take(1) , map(isAustenit => {

        if(isAustenit == null || !isAustenit){
          return false;
        }


      return true;
    }));
  }

}

