import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map , take} from 'rxjs/operators';

@Injectable()
export class DeactivateAuthGuardService implements CanActivate {

  canNavigate: boolean;

  constructor(private authService: AuthService, 
              private router: Router ) {

              }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {


    return this.authService.user.pipe(take(1) , map(user => {

        if(user != null){
          this.goToOrders();
          return false;
        }


      return true;
    }));
  }

  private goToOrders(){
    this.router.navigate(['/orders']);
  }
}

