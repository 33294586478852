import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(private authService : AuthService,
              private router : Router) { }

  ngOnInit() {
    this.authService.user.subscribe(u => {
      if(u!=null){
        this.router.navigate(['/orders']);
      }
    })
  }

  navigateContact(){
    this.router.navigate(['/contact']);
  }


  logIn(mail : string, pass : string){
    this.authService.logIn(mail , pass)
  }

  naviagteResetPassword(){
    this.router.navigate(['/new-password']);
  }
}
