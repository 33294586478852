import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreDocument, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Observable } from "rxjs";

import { first, map } from "rxjs/operators";
import { Order } from "../models/order";
import { OrderStatus } from "../models/OrderStatus";
import { User } from "../models/user";
import { GeneralSpinnerService } from "./spinner.service";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private afs: AngularFirestore,private spinner: GeneralSpinnerService) { }

  getOrders(user: User): Observable<Order[]> {
  
    this.spinner.setSpinner(true);

    let result = this.afs.collection<Order>('orders',
    ref => ref.where('userId', '==', user.id).orderBy('lastUpdate', 'desc'))
    .snapshotChanges()
    .pipe(map(this.mapOrders));
    this.spinner.setSpinner(false);

    return result;
  }

  deleteOrder(order:Order){
    return new Promise((resolve, reject) =>{
      const orderRef: AngularFirestoreDocument<Order> = this.afs.doc(`orders/${order.id}`);

      orderRef.delete().then(r=> resolve(r)).catch(err=> reject(err))
    })
  }

  createOrUpdateOrder(order:Order){
    
    this.spinner.setSpinner(true);

    order.lastUpdate = new Date();

    return new Promise((resolve, reject) =>{
      const orderRef: AngularFirestoreDocument<Order> = this.afs.doc(`orders/${order.id}`);

      orderRef.get().subscribe(doc=>{
  
        if(doc && doc.exists){
          orderRef.update(order).then(x=>{resolve(undefined)})
                         .catch(err=> {;reject(err)})
        }
        else{
          orderRef.set(order).then(()=>resolve(undefined))
                           .catch(err=> reject(err))
        }

        
      
      this.spinner.setSpinner(false);
    } , err => {
      reject(err)
    })
    })
  }

  getActiveOrders(): Observable<Order[]> {
    return this.afs.collection<Order>('orders',
      ref => ref.where('status', '==', OrderStatus.InProcess).orderBy('lastUpdate', 'desc'))
      .snapshotChanges()
      .pipe(map(this.mapOrders))
  }

  getAllOrders(): Observable<Order[]> {
    return this.afs.collection<Order>('orders',
      ref => ref.orderBy('lastUpdate', 'desc'))
      .snapshotChanges()
      .pipe(map(this.mapOrders))
  }

  getOrder(documentId: string): Observable<Order | Order[]> {
    return this.afs.collection<Order>('orders',
      ref => ref.where("__name__", '==', documentId))
      .snapshotChanges()
      .pipe(map(this.mapOrders))
      .pipe(first<Order[], Order>())
  }

  private mapOrders(orders: DocumentChangeAction<Order>[]): Order[] {
    return orders.map(order => {
      let doc = order.payload.doc.data();

      let creationDate = new Date(1970, 0, 1);
      creationDate.setSeconds((doc as any).creationDate.seconds)

      let lastUpdateDate = new Date(1970, 0, 1);
      lastUpdateDate.setSeconds((doc as any).lastUpdate.seconds)

      let data = doc as Order

      if((doc as any).endedDate){
        let endedDate = new Date(1970, 0, 1);
        endedDate.setSeconds((doc as any).endedDate.seconds)
        data.endedDate = endedDate;
      }

      data.creationDate = creationDate
      data.lastUpdate = lastUpdateDate
      data.id = order.payload.doc.id

      return data;
    })
  }



}