import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {

  public emailSent : boolean = false;

  constructor(private router : Router, private authService : AuthService, private dialogService: DialogService) { }

  ngOnInit() {}

  navigateBack(){
    this.router.navigate([""]);
  }

  sendReset(email){
    if(email.trim()!=""){
      this.authService.resetPassword(email).then(()=>{
        this.emailSent = true;
      })
      .catch(err=> {
        this.dialogService.showDIalog("Error");

      });
    }
  }

}
