import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '../models/order';
import { DialogService } from '../services/dialog.service';
import { OrdersService } from '../services/orders.service';
import { GeneralSpinnerService } from '../services/spinner.service';

@Component({
  selector: 'app-external-view',
  templateUrl: './external-view.component.html',
  styleUrls: ['./external-view.component.scss'],
})
export class ExternalViewComponent implements OnInit {
  public templateOrder: Order= undefined;

  constructor(private orderService : OrdersService,
              private route : ActivatedRoute,
              private spinner : GeneralSpinnerService,
              private router : Router,
              private dialogService : DialogService) { }

  ngOnInit() {
    this.route.params.subscribe(params=>{
      if(params["id"]){
        this.orderService.getOrder(params["id"])
                         .subscribe(order => {
                          this.spinner.setSpinner(false);

                          if((order as Order[]).length == 0){
                            this.router.navigate(['login']).then(x=>{})
                          }

                            this.templateOrder = order[0] as Order
                            debugger
                        }, err => {
                          this.dialogService.showDIalog("Error");
                          this.spinner.setSpinner(false);
                        })
      }else{
        this.spinner.setSpinner(false);

      }
    })
  }

}
