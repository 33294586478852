import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { AngularFireAuth } from'@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { GeneralSpinnerService } from '../services/spinner.service';
import { DialogService } from '../services/dialog.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: BehaviorSubject<User> = new BehaviorSubject(null);

  public userToken: BehaviorSubject<string> = new BehaviorSubject(null);



  public isAustenitUser: BehaviorSubject<boolean> = new BehaviorSubject(null);


  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router : Router,
    private menuController : MenuController,
    private spinner : GeneralSpinnerService,
    private dialogService : DialogService
  ) {
    
    this.spinner.setSpinner(true);
    this.afAuth.user.subscribe((user) => {
      this.spinner.setSpinner(true);
      this.pushAuthenticatedUser(user);
      this.spinner.setSpinner(false);

    });

    this.isAustenitUser.subscribe(x=> {
      this.spinner.setSpinner(true);

      if(x){
        this.user.next(null)
        this.router.navigate(['/admin']);
      }
      this.spinner.setSpinner(false);

    })
  }

  public resetPassword(email): Promise<void>{
    return this.afAuth.sendPasswordResetEmail(email)
  }

  public isLoggedIn(): Observable<boolean> {
    return this.afAuth.authState.pipe(map(x => {
      if (x != null && x != undefined) {
        return true;
      }

      return false;
    }));
  }

  private setBearer(user){
    user.getIdToken().then(token=>{
      this.userToken.next(token);
    })
  }

  logIn(mail : string, password : string){
      this.spinner.setSpinner(true);
    
    this.afAuth.signInWithEmailAndPassword(mail, password).then(credentials => {
      this.pushAuthenticatedUser(credentials.user);
      this.spinner.setSpinner(false);

      
    })
    .catch(err => {
      this.dialogService.showDIalog("Error");
      this.spinner.setSpinner(false);

    })
  }

  setUser(u : User){
    this.user.next(u)
  }

  pushAuthenticatedUser(firebaseUser){
    if(firebaseUser == null)
      return;

    this.setBearer(firebaseUser);
    
    let u : User = {
      id : firebaseUser.uid,
      mail : firebaseUser.email,
    }
    
    if(this.isAustenitEmail(u.mail)){
      this.isAustenitUser.next(true);
    }else{
      this.isAustenitUser.next(false);


      this.getUser(u).then(user=>{
        this.setUser(user)
      }).catch( err => {
        throw err;
      });
    }
  }

  private isAustenitEmail(email){
    return email.includes("@austenit")
  }

  public createUserInAuth(email : string) : Promise<string>{
    return new Promise((resolve ,reject) => {

      this.afAuth.createUserWithEmailAndPassword(email, "FAKE_TEMPORAL_PASSWORD_NEEDS_ACTIVATION_863121-!")
      .then(user=> {
        resolve(user.user.uid)
      })
      .catch(err=> {
        reject(err)
      })
    })
  }

  public sendPasswordResetAndConfirmation(email : string){

    return new Promise((resolve, reject) => {
      
    this.afAuth.sendPasswordResetEmail(email)
                .then(x => {
                  resolve(undefined)
                })
                .catch(err=>{
                  reject(err)
                })
    })
  }

 getUser(user : User ): Promise<User> {

      return new Promise((resolve , reject) => {

        if(typeof user.id =='undefined' && !user?.id ){
          reject('Missing user ID.');
        }

        const userRef: AngularFirestoreDocument<User> = this.afs.doc(`user/${user.id}`);
        userRef.get().subscribe(u =>{
          if(u != null && u.exists){
            resolve(u.data() as User)
          }else{
            reject();
          }
          
        } , err => {
          reject(err)
        })
      });

    }

    public logOut(){
      this.afAuth.signOut().then( _ => {
        this.user.next(null)
        this.router.navigate(['/login']);
        this.menuController.close();
      });
    }


}
