import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {

  constructor(private router : Router,
    
    ) { }

  ngOnInit() {}

  navigateBack(){
    this.router.navigate(["/orders"]);
  }

  call(){
    //this.callNumber.callNumber("50224724437", true)
    //    .then(res => console.log('Launched dialer!', res))
    //    .catch(err => console.log('Error launching dialer', err));
  }

  openMaps(){


    let destination = "Austenit, S. A., 2 av. 0 78 Sec. B5 San Cristóbal I, z. 8, Guatemala";

  //ios
	//window.open('maps://?q=' + destination, '_system');
	let label = encodeURI('Austenit');
	window.open('geo:0,0?q=' + destination + '(' + label + ')', '_system');

  }

}
