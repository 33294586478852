
export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyApyfZB0lLbQU0ggXMG2rcHcumxgZnY_GM",
    authDomain: "austenit-9abb4.firebaseapp.com",
    projectId: "austenit-9abb4",
    storageBucket: "austenit-9abb4.appspot.com",
    messagingSenderId: "404630438452",
    appId: "1:404630438452:web:c2849ab1b3956657cf0061",
    measurementId: "G-2S25CH2F2Z"
  },
  useFirebaseEmulators : true
};