import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map , take} from 'rxjs/operators';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GeneralSpinnerService } from '../services/spinner.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  canNavigate: boolean;

  constructor(private authService: AuthService, 
              private router: Router,
              private spinner : GeneralSpinnerService ) {

              }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.spinner.setSpinner(true);


    let can = this.authService.isLoggedIn().pipe(take(1) , map(isAuthenticated => {


      this.authService.isAustenitUser.subscribe(isAustenit => {

        
      
      this.spinner.setSpinner(false);
        if(isAustenit){
          this.goToAdmin();
          return false;
        }

      })

        if(!isAuthenticated){
          this.goToLogin();
          return false;
        }
        else if(isAuthenticated  && route.routeConfig.path != 'login' ){
          return true
        }

      return false;
    }));

    return can
  }

  private goToLogin(){
    this.router.navigate(['/login']);
  }

  private goToAdmin(){
    this.router.navigate(['/admin']);
  }
}

