import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { User } from './models/user';
import { GeneralSpinnerService } from './services/spinner.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public user : User
  public isAustenitUser : boolean;

  public showSpinner = true;


  public appPages = [
    { title: 'Ordenes', url: '/orders', icon: 'folder-open' },
    { title: 'Contactarnos', url: '/contact', icon: 'call' }
  ];

  public appPagesAdmin = [
    { title: 'Ordenes', url: '/admin', icon: 'folder-open' },
    { title: 'Nueva orden', url: '/admin/new-order', icon: 'add' },
    { title: 'Nuevo usuario', url: '/admin/new-user', icon: 'add' }
  ];

  constructor(private authService : AuthService,
    private spinnerService: GeneralSpinnerService,
    private router : Router) {
      
      this.startApp()
    
      this.spinnerService.showSpinner.subscribe(showSpinner=> {
        this.showSpinner = showSpinner;
      });
    
    }

  private startApp(){
    this.authService.isAustenitUser.subscribe(isAustUser => {
      this.spinnerService.setSpinner(true);

      if(isAustUser != null){
        this.isAustenitUser = isAustUser;

        if(isAustUser){
          this.user = null  
        }
        this.appPages = isAustUser ? this.appPagesAdmin : this.appPages;

        this.spinnerService.setSpinner(false);
      }
    })

    this.authService.user.subscribe(user=>{
      this.spinnerService.setSpinner(true);

      if(user == null){
        this.user = null
        return;
      }

        this.user = user;
      this.spinnerService.setSpinner(false);

    })
  }

  public navigateContact(){
    this.router.navigate(['/contact'])
  }

  public logout(){
    this.authService.logOut();
  }
}
