import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";

@Injectable({
    providedIn: 'root'
  })
  export class GeneralSpinnerService { 
    public showSpinner : BehaviorSubject<boolean> = new BehaviorSubject(true)

    constructor() { }
  
    setSpinner(bool : boolean){
      this.showSpinner.next(bool)
    }
  }