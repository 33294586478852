import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import {provideAuth, getAuth} from '@angular/fire/auth';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuardService } from './auth/guard.service';
import { AuthService } from './auth/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { DeactivateAuthGuardService } from './auth/deactivateGuard.service';
import { OrdersService } from './services/orders.service';
import { CommonModule, DatePipe } from '@angular/common';
import { AdminAuthGuardService } from './auth/adminActivateGuard.service';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { ExternalViewComponent } from './external-view/external-view.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { DialogService } from './services/dialog.service';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [AppComponent , ContactComponent, LoginComponent , ExternalViewComponent, NewPasswordComponent ],
  entryComponents: [AppComponent],
  imports: [ BrowserModule,
            IonicModule.forRoot(),
            CommonModule,
            AppRoutingModule,
            HttpClientModule,
            provideAuth(() => getAuth()),
            AngularFireModule.initializeApp(environment.firebase),
            AngularFireModule.initializeApp(environment.firebase, "fireAdminAuth")
            ],
  providers: [
                AdminAuthGuardService,
                DialogService,
                AuthGuardService,
                DeactivateAuthGuardService,
                AuthService,
                DatePipe ,
                OrdersService,
                { provide: RouteReuseStrategy,
                useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
  exports : []
})
export class AppModule {}
